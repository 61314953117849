import React, { Component } from 'react'

import "./errors.scss"

class Loading extends Component {
    render () {
        let status = ( this.props.errors && this.props.errors.response && this.props.errors.response.status ? this.props.errors.response.status : '' )
        let message = ( this.props.errors && this.props.errors.response && this.props.errors.response.statusText ? this.props.errors.response.statusText : '' )
        let details = ( this.props.errors && this.props.errors.response && this.props.errors.response.data && this.props.errors.response.data.message ? this.props.errors.response.data.message : '' )
        return(
            <div className='error'>
                <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">{status}</h4>
                    <p>{message}</p>
                    <hr />
                    <p>{details}</p>
                </div>
            </div>
        )
    }
}
    
export default Loading
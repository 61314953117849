import React, { useState } from 'react';
import Modal from './modal'

import './todo.scss'



function generateRandom ( min = 0, max = 100 ) {
    // find diff
    let difference = max - min;
    // generate random number 
    let rand = Math.random();
    // multiply with difference 
    rand = Math.floor( rand * difference);
    // add with min value 
    rand = rand + min;
    return rand;
}

const Todo = (props) => {
    const [open, setOpen]= useState( false );
    const [todo, setTodo]= useState( props.todo );

    let Id = props.id
    var now = new Date();
    var start = new Date(now.getFullYear(), 0, 0);
    var diff = now - start;
    var oneDay = 1000 * 60 * 60 * 24;
    var day = Math.floor(diff / oneDay);
    let classTodo = 'TodoInfos circle'
    let circleColor = [
        'yellow1',
        'purple1',
        'green1',
        'red1',
        'blue1',
        'orange1',
        'yellow2',
        'purple2',
        'green2',
        'red2'
    ]
    if ( parseInt( todo.the_day ) < parseInt( day ) ) {
        classTodo = classTodo + ' circleFull' + ' ' + circleColor[generateRandom(0,circleColor.length)]
    }
    if ( parseInt( todo.the_day ) === parseInt( day ) ) {
        classTodo = classTodo + ' circleToDay'
    }
    let Name = todo.name
    let Description = todo.description
    if ( Name && Description && !parseInt(todo.status) ) {
        classTodo = classTodo + ' circleEnable' 
    }
    if ( Name && Description && parseInt(todo.status) ) {
        classTodo = classTodo + ' circleChecked'
    }

    const toggleModal = ( todo ) => {
        setOpen( o => !o )
        if ( todo && todo.id ) {
            setTodo( todo  )
        }
    }
    return (
        <div className={ classTodo } id={`todo${Id}`} onClick={toggleModal}>
            <div className="TodoInfosContent col-sm-12">
                {
                    Name && Description && open && <Modal todo={todo} toggleModal={toggleModal}/>
                }
            </div>
        </div>
    )
}

export default Todo

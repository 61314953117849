import React from 'react'
import { Redirect } from "react-router-dom";

class Auth extends React.Component {

    constructor( props ) {
        super( props )
        this.state = {
            redirect: false,
            isLogged: false,
        }
    }

    componentDidMount() {
        let providerName = this.props.match.params.provider
        let providerToken = this.props.match.params.token

        if ( ! providerName || ! providerToken ) {
            this.setState({ redirect: true })
        } else {
            localStorage.setItem( 'api_token', providerToken )
            this.setState({ isLogged: true })
        }
    }

    render () {
        if ( this.state.redirect ) {
            return ( <Redirect to="/login" />)
        }
        if ( this.state.isLogged ) {
            return ( <Redirect to="/todos" />)
        }
        return (
            <>
            </>
        )
    }

}

export default Auth

import React from 'react'

import Auth from './components/oauth/Auth.js'
import Home from './pages/home'
import Register from './pages/register'
import Login from './pages/login'
import Todos from './pages/todos'
import TodoAdd from './pages/todos/new'

import { Switch, Route } from 'react-router-dom'

class AppRouter extends React.Component {
    render() {
        return (
            <>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/login" component={Login} />
                    <Route path="/login/:provider/:token" component={Auth} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/todos" component={Todos} />
                    <Route exact path="/todo/new" component={TodoAdd} />
                </Switch>
            </>
        )
    }
}

export default AppRouter

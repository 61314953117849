import React, { Component } from 'react'
import Axios from 'axios'
import Popup from 'reactjs-popup'

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


import "./modal.scss"

require('dotenv').config()

class Modal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: false,
            openModal: true,
            limit: 10,
            todo: []
        }
    }

    componentDidMount() {
        this.setState({ todo: this.props.todo })
    }

    closeModal = () => {
        this.setState({ openModal: false }, () => {
            this.props.toggleModal(this.state.todo)
        })
    }

    onStatusChange = (val) => {
        if ( localStorage.getItem( 'api_token' ) ) {
            let headers = {
                headers: {
                    'API-TOKEN' : localStorage.getItem( 'api_token' )
                }
            }
            let bodyFormData = new FormData();
            bodyFormData.set( 'status' , (this.state.todo.status ? 0 : 1) )

            var endPoint = process.env.REACT_APP_API_URL + '/api/todo/' + this.state.todo.id
            Axios.post( endPoint, bodyFormData, headers )
            .then( res => {
                this.setState({ todo: res.data.success })
            })
            .catch( errors => {
                if ( errors.response.status === 403 ) {
                    localStorage.setItem( 'api_token', '' )
                    localStorage.clear()
                    this.setState({ redirect: true })
                } else {
                    console.warn( errors.response )
                }
            })
        } else {
            this.setState({ redirect: true })
        }
    }

    render() {
        return (
            <>
                <Popup open={this.state.openModal} closeOnDocumentClick onClose={this.closeModal}>
                    <div className="modal">
                        <button onClick={this.closeModal} className="close">&times;</button>
                        <div className={`content text-start`}>
                            <div className="TodoInfos" >
                                <div className="row" >
                                    <div className="TodoInfosContent col-sm-12">
                                        <p className="TodoInfosTitle">
                                            <span className='label'>
                                                Titre
                                            </span>
                                            <span>{this.state.todo.name}</span>
                                        </p>
                                        <p className="TodoInfosDesc">
                                            <span className='label'>
                                                Description
                                            </span>
                                            <span>{this.state.todo.description}</span>
                                        </p>
                            			<FormGroup className="TodoFilters" >
                                            <FormControlLabel
                                                control={<Switch
                                                    checked={parseInt(this.state.todo.status) ? true : false}
                                                    size="small"
                                                    onChange={this.onStatusChange}
                                                    name="todoStatus"
                                                />}
                                                label={ parseInt(this.state.todo.status) ? "C'est fait :)" : "Bientôt fait ;)" }
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
            </>
        )
    }
}

export default Modal;

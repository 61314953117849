import React from 'react'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import InputText from '../../input/text';

import './datepicker.scss'

const InputDatePicker = (props) => {
    let { htmlId, label, value, onChange, errors } = props
    return (
        <>
            <div className="input">
                <DatePicker 
                    selected={value}
                    onChange={onChange}
                    locale="fr-FR"
                    dateFormat='dd-MM-yyyy'
                    required={true}
                    customInput={                            <InputText
                        htmlId={htmlId}
                        label={label}
                    />}
                />
                {/* <label htmlFor={htmlId} className="form-label">{label}</label> */}
                { errors ? <div className="text-danger invalid-feedback" >{ errors }</div> : '' }
            </div>
        </>
    )
}

export default InputDatePicker
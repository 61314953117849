import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Axios from 'axios'

import MainMenu from '../../menus/main'

import InputText from '../../components/input/text'
import InputDatePicker from '../../components/input/datepicker'
import InputButton from '../../components/input/button'

require('dotenv').config()

class TodoAdd extends Component {
    constructor( ) {
        super()
        this.state = {
            name: '',
            description: '',
            the_day: '',
            todoAdded: false,
            errors: [],
            redirect: false,
        }
    }

    componentDidMount() {
        if ( ! localStorage.getItem( 'api_token' ) ) {
            this.setState({ redirect: true })
        }
    }

    handleChangeName = (event) =>  {
        this.setState({ name : event.target.value })
    }

    handleChangeDescription = (event) =>  {
        this.setState({ description : event.target.value })
    }

    handleChangeTheDay = (event) =>  {
        var currentDate = new Date( event );
        console.log( currentDate )
        this.setState({ the_day : currentDate }, () => {console.log(this.state.the_day)})
    }

    handleSubmit = (event) => {
        event.preventDefault()
        var theDay = new Date( this.state.the_day )
        theDay = Number( theDay.getDate() ) + '-' + ( theDay.getMonth() + 1 ) + '-' + theDay.getFullYear()
        console.log( 'theDay: ', theDay )

        let bodyFormData = new FormData();
        bodyFormData.set( 'name' , this.state.name )
        bodyFormData.set( 'description' , this.state.description )
        bodyFormData.set( 'the_day', theDay )

        let headers = {
            headers: {
                'API-TOKEN' : localStorage.getItem( 'api_token' )
            }
        }

        Axios.post( process.env.REACT_APP_API_URL + '/api/todo', bodyFormData, headers )
        .then(res => {
            if ( res.data.success ) {
                this.setState({ todoAdded: true })
            }
        })
        .catch(errors =>  {
            if ( errors.response.status === 403 ) {
                localStorage.setItem( 'api_token', '' )
                localStorage.clear()
                this.setState({ redirect: true })
            } else if ( errors.response.status === 412 ) {
                this.setState({ errors: errors.response.data.errors }, () => { console.warn( this.state.errors )})
            } else if ( errors.response.status === 500 ) {
                // this.setState({ errors: errors }, () => { console.warn( this.state.errors )})
            } else {
                console.warn( errors.response )
            }
        });
    }

    render () {
        if ( this.state.redirect ) {
            return ( <Redirect to="/login" />)
        }
        if ( this.state.todoAdded ) {
            return ( <Redirect to="/todos" />)
        }
        return (
            <>
                <MainMenu currentPage="Todos" />
                <div className="Todos TodoAdd animate__animated animate__bounceInRight">
                    <div className="container">
                        <h2 className="my-5">Ajouter une nouvelle tâche</h2>
                        <form method="POST" onSubmit={this.handleSubmit} className="text-start">
                            <InputText
                                htmlId='TodoInputName'
                                label='Nom pour cette nouvelle tâche'
                                onChange={this.handleChangeName}
                                errors={this.state.errors.name}
                            />
                            <InputText
                                htmlId='TodoInputDescription'
                                label='Description pour cette nouvelle tâche'
                                onChange={this.handleChangeDescription}
                                errors={this.state.errors.description}
                            />
                            <InputDatePicker
                                htmlId='TodoInputTheDay'
                                label='Date de fin pour cette nouvelle tâche'
                                value={this.state.the_day}
                                onChange={this.handleChangeTheDay}
                                errors={this.state.errors.the_day}
                            />
                            { this.state.errors && this.state.errors.code === 'bad_credentials' ? <div className="alert alert-danger" >{ this.state.errors.msg }</div> : '' }
                            <p className="text-center">
                                <InputButton label='Valider la tâche' />
                            </p>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

export default TodoAdd

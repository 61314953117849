import React, { Component } from 'react'
import Axios from 'axios'
import { Redirect, Link } from 'react-router-dom'

import Loading from '../../components/loading/'
import Errors from '../../components/errors/'

import MainMenu from '../../menus/main/'
import Todo from '../../components/todo/'

import './todos.scss'

require('dotenv').config()

class Todos extends Component {
    constructor( ) {
        super()
        this.state = {
            showLoading: false,
            redirect: false,
            todos: [],
            errors: [],
        }
    }

    getDaysInMonth = (month, year) => {
       return new Date(year, month, 0).getDate();
    };

    componentDidMount() {
        var currentDate = new Date();
        var currentDateYear = currentDate.getYear();
        var allDays = 0
        for (let i = 1; i <= 12; i++) {
            allDays = allDays + this.getDaysInMonth( i, currentDateYear )
        }
        var todos = []
        for (let i = 1; i < allDays; i++) {
            todos[ i ] = {
                the_day: i
            }

        }
        this.setState({ showLoading: true }, () => {
            this.setState({ todos }, () => {
            if ( localStorage.getItem( 'api_token' ) ) {
                let headers = {
                    headers: {
                        'API-TOKEN' : localStorage.getItem( 'api_token' )
                    }
                }
                Axios.get( process.env.REACT_APP_API_URL + '/api/todos', headers )
                .then( res => {
                    var todos = this.state.todos
                    var myTodos = res.data.success
                    if ( myTodos && myTodos.length > 0 ) {
                        myTodos.map( (todo) => {
                            todos[ todo.the_day ] = todo
                            return todos
                        })
                    }
                    this.setState({ todos: todos, showLoading: false })
                })
                .catch( errors => {
                    console.log( errors )
                    if ( errors.response.status === 403 ) {
                        localStorage.setItem( 'api_token', '' )
                        localStorage.clear()
                        this.setState({ redirect: true })
                    } else if ( errors.response.status === 500 ) {
                        this.setState({ errors: errors })
                    } else {
                        console.warn( errors.response )
                    }
                })
            } else {
                this.setState({ redirect: true })
            }
            })
        })
    }

    render () {
        if ( this.state.redirect ) {
            return ( <Redirect to="/login" />)
        }
        if ( this.state.errors && this.state.errors.length > 0 ) {
            return ( <Errors errors={this.state.errors} />)
        }
        return (
            <>
                { this.state.showLoading && <Loading /> }
                <MainMenu currentPage="Todos" />
                <div className="Todos animate__animated animate__bounceInRight">
                    <div className="container text-left">
                        <Link className="btn btn-primary mb-5 todo-add" to="/todo/new"></Link>
                        <div className="container">
                            {
                                this.state.todos.map( (todo) =>
                                    <Todo key={todo.id} todo={todo} />
                                )
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Todos
import React, { Component } from 'react'
import { Redirect, Link } from "react-router-dom";

import './mainmenu.scss'

class MainMenu extends Component {
    constructor() {
        super()
        this.state = {
            isLogged: false,
            redirect: false,
        }
    }

    componentDidMount() {
        if ( localStorage.getItem( 'api_token' ) ) {
            this.setState({ isLogged: true })
        }
    }

    logout = () => {
        localStorage.setItem( 'api_token', '' )
        localStorage.clear()
        this.setState({ isLogged: false })
        this.setState({ redirect: true })
    }

    checkCurrentPage = (current, ref) => {
        if ( current === ref ) {
            return 'nav-link fw-bold';
        }
        return 'nav-link';
    }

    render () {
        if ( this.state.redirect ) {
            return ( <Redirect to="/login" />)
        }
        return (
            <>
                <nav className="mainmenu navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/">Révèle toi - Projets</Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                {
                                    this.state.isLogged ?
                                        <>
                                            <li className="nav-item">
                                                <Link className={`nav-link ${ this.props.currentPage === 'Todos' ? 'fw-bold' : '' }`} to="/todos" >To do</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={this.checkCurrentPage(this.props.currentPage, 'Logout')} to="#" onClick={this.logout}>Deconnexion</Link>
                                            </li>
                                        </>
                                    :
                                        <>
                                            <li className="nav-item">
                                                <Link className={this.checkCurrentPage(this.props.currentPage, 'Login')} to="/login">Login</Link>
                                            </li>
                                            {/* <li className="nav-item">
                                                <Link className={this.checkCurrentPage(this.props.currentPage, 'Inscription')} to="/register">Inscription</Link>
                                            </li> */}
                                        </>
                                }
                            </ul>
                        </div>
                    </div>
                </nav>
            </>
        )
    }
}

export default MainMenu
